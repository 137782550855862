<template>
  <div id='contact'>
    <Header />
    <h1 class="contact_title">CONTACT US</h1>
    <div class="line"></div>
    <div v-if="dataList" class="contact_main">
      <div class="contact_main_item">
        <div class="contact_main_name">Email</div>
        <div class="contact_main_value">{{dataList.email}}</div>
      </div>
      <div class="contact_main_item">
        <div class="contact_main_name">PHONE</div>
        <div class="contact_main_value">{{dataList.phone}}</div>
      </div>
      <div class="contact_main_item">
        <div class="contact_main_name">Address (google map)</div>
        <div class="contact_main_value">{{dataList.address}}</div>
      </div>
      <div class="contact_main_item">
        <div class="contact_main_name">OFFICE HOUR</div>
        <div class="contact_main_value">{{dataList.office_hour}}</div>
      </div>
    </div>
    <div v-else class="no_data"></div>
    <div class="bg_left"><img src="@assets/image/bg_left.png" alt=""></div>
    <div class="bg_right"><img src="@assets/image/bg_right.png" alt=""></div>
    <Footer />
  </div>
</template>

<script>
import Header from '@cpt/Header'
import Footer from '@cpt/Footer'
import {getNav} from '@api'

export default {
  data () {
    return {
      dataList: null
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    this.getData()
  },
  methods:{
    getData() {
      getNav().then(res => this.dataList = res.data.contact_us)
    }
  }
}
</script>

<style lang='less' scoped>
#contact {
  position: relative;
  overflow: hidden;
  .contact_title {
    width: 1200px;
    margin: 178px auto 0;
    font-size: 49px;
    font-weight: bold;
    animation: move 1s;
  }
  @keyframes move {
    0% {
      transform: translateX(-500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .line {
    position: absolute;
    top: 305px;
    right: 0;
    width: 60%;
    height: 1px;
    background: #e5e5e5;
    z-index: -1;
  }
  .contact_main {
    width: 1200px;
    margin: 0 auto 82px;
    .contact_main_item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 160px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 18px;
      .contact_main_name {
        flex: 2;
        margin-left: 66px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .contact_main_value {
        flex: 4;
      }
    }
  }
  .bg_left {
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
  }
  .bg_right {
    position: absolute;
    top: 875px;
    right: 0;
    z-index: -1;
  }
  .no_data {
    width: 100%;
    height: 143px;
  }
}
</style>